export default class featurePopup{
    constructor() {
        this.popupWrapper = document.querySelectorAll('.popupWrapper')
        if(this.popupWrapper){
            this.openBtns = document.querySelectorAll('.popup')
            this.closeBtns = document.querySelectorAll('.popupClose')
            this.attachEventListner()
        }
    }
    attachEventListner(){
        this.openBtns.forEach( (element,index) => {
            element.addEventListener('click', ()=> {
                this.popupWrapper[index].classList.toggle('hidden')
                document.body.classList.toggle('overflow-hidden')
            })
        })
        this.closeBtns.forEach( (element,index) => {
            element.addEventListener('click', ()=> {
                this.popupWrapper[index].classList.toggle('hidden')
                document.body.classList.toggle('overflow-hidden')
            })
        })
        this.popupWrapper.forEach( element => {
            element.addEventListener('click', (event)=> {
            if(event.target.classList.contains('popupWrapper')){
                element.classList.toggle('hidden')
                document.body.classList.toggle('overflow-hidden')
            }
            })
        })
      
     
    }
}