import Cookies from "js-cookie";
import "svgxuse";

import partnerSlider from "./components/partnerSlider";
import teamSlider from "./components/teamSlider";
import relatedNewsSlider from "./components/relatedSlider";
import testimonialSlider from "./components/testimonialSlider";
import historySlider from "./components/historySlider";
import scrollProgress from "./components/scrollProgress";
import scrollIndicator from "./components/scrollIndicator";
import Navigation from "./components/navigation";
import secondaryMenu from "./components/secondaryMenu.js";
import questionnaire from "./components/questionnaire";
import featurePopup from "./components/featurePopup";
import ContactForm from "./components/contactForm";
import popupSticky from "./components/popupSticky";

// // Only continue if polyfills are actually needed
// if (!(('scrollBehavior') in document.documentElement.style)) {
//   // Wait until the Polyfills are loaded
//   Promise.all([
//     import('smoothscroll-polyfill'),
//     import('smoothscroll-anchor-polyfill')
//   ])
//   // then use the modules however you want
//   .then(([smoothscrollPolyfill, smoothscrollAnchorPolyfill]) => {
//     // (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
//     smoothscroll.polyfill();
//   });
// }

// Import any polyfill to enable smoothscroll for JS APIs
import smoothscroll from "smoothscroll-polyfill";

// Import this package to apply the smoothscroll to anchor links
import smoothscrollAnchorPolyfill from "smoothscroll-anchor-polyfill/dist/index.min.js";

// (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
smoothscroll.polyfill();

//sticky polyfill for ie
import stickybits from "stickybits";
stickybits(".stickyDiv");

document.addEventListener("DOMContentLoaded", () => {
  // smoothscroll.polyfill();
  new scrollProgress();

  new scrollIndicator(document.getElementById("steps"));

  new Navigation(".navigation");

  new featurePopup();
  new ContactForm();
  new popupSticky();

  // on scroll change navigation background
  let last_known_scroll_position = 0;
  let ticking = false;
  const nav = document.querySelector(".navigation");

  function navigationColorCheck(scroll_pos) {
    if (scroll_pos > 25) {
      nav.classList.add("navigation--scroll");

      if (nav.classList.contains("nav--white")) {
        nav.classList.remove("nav--white");
        nav.classList.add("nav--green");
      }
    } else if (scroll_pos < 20) {
      nav.classList.remove("navigation--scroll");

      if (nav.classList.contains("nav--green")) {
        nav.classList.remove("nav--green");
        nav.classList.add("nav--white");
      }
    }
  }

  window.addEventListener(
    "scroll",
    () => {
      last_known_scroll_position = window.pageYOffset;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          navigationColorCheck(last_known_scroll_position);
          ticking = false;
        });

        ticking = true;
      }
    },
    { passive: true }
  );

  // Cookie Notice
  function openCookiePopup() {
    document.querySelector("#cookie").style.display = "block";
  }

  function closeCookiePopup() {
    document.querySelector("#cookie").style.display = "none";

    if (!Cookies.get("cookiePopup")) {
      Cookies.set("cookiePopup", "false", {
        expires: 90,
        path: "",
      });
    }
  }

  if (!Cookies.get("cookiePopup")) {
    openCookiePopup();
  }

  document
    .querySelector("#cookieBtn")
    .addEventListener("click", closeCookiePopup.bind());

  //Sliders

  const partnerSlide = document.querySelector(".partnerSliderContainer");
  if (partnerSlide) {
    new partnerSlider(partnerSlide);
  }

  const relatedSlider = document.querySelector(".relatedSliderContainer");
  if (relatedSlider) {
    new relatedNewsSlider(relatedSlider);
  }

  const teamSlide = document.querySelector(".teamSliderContainer");
  if (teamSlide) {
    new teamSlider(teamSlide);
  }

  const testimonialSlide = document.querySelector(
    ".testimonialSliderContainer"
  );
  if (testimonialSlide) {
    new testimonialSlider(testimonialSlide);
  }

  const historySlide = document.querySelector(".historySliderContainer");
  if (historySlide) {
    new historySlider(historySlide);
  }

  //secondary nav
  const secondaryNav = document.querySelector(".secondary-nav");
  if (secondaryNav) {
    new secondaryMenu(secondaryNav);
  }

  const questionContainer = document.querySelector(".questionContainer");
  if (questionContainer) {
    new questionnaire(questionContainer);
  }
});
