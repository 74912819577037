export default class secondaryMenu {
  constructor(container) {
    this.mobile = window.matchMedia("(max-width: 768px)").matches;
    this.mobileSmall = window.matchMedia("(max-width: 425px)").matches;

    if (this.mobile) {
      this.container = container;
      this.button = this.container.querySelector(".submitButton");
      this.navItems = Array.from(
        this.container.querySelectorAll(".nav-item > a")
      );
      this.sections = document.querySelectorAll("section");
      this.navContainer = this.container.querySelector(".navContainer");

      window.addEventListener(
        "scroll",
        () => {
          this.sections.forEach(element => {
            console.log("asda");
            if (
              !this.checkVisible(element, 300, "above") &&
              this.checkVisible(element, 200) &&
              !this.checkVisible(element, 300, "below")
            ) {
              this.navItems.forEach(element => {
                element.classList.remove("active");
              });

              this.container
                .querySelector('[href="#' + element.id + '"]')
                .classList.add("active");
              this.moveActive();
              this.shrinkButton();
            }
          });
        },
        { passive: true }
      );
    }
  }
  checkVisible(elm, threshold, mode) {
    threshold = threshold || 0;
    mode = mode || "visible";

    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === "above"
      ? above
      : mode === "below"
      ? below
      : !above && !below;
  }

  moveActive() {
    let offset = this.container.querySelector(".nav-item > .active").offsetLeft;
    this.navItems[this.navItems.length - 1].style.marginRight =
      this.navContainer.offsetWidth -
      24 -
      this.navItems[this.navItems.length - 1].offsetWidth +
      "px";

    this.navContainer.scroll({
      left: offset - 24,
      behavior: "smooth"
    });
  }
  shrinkButton() {
    if (this.navItems[0].classList.contains("active")) {
      this.button.classList.remove("shrink");
    } else {
      this.button.classList.add("shrink");
    }
  }
}
