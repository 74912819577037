// Import all stable polyfills
// import 'core-js/stable';

// Alternateively load all polyfills
import 'core-js';
import 'regenerator-runtime/runtime';

import 'intersection-observer';

console.log("Legacy Javascript loaded.");

import './app.js';
