import {
    tns
}
from "tiny-slider/src/tiny-slider.module.js";

export default class testimonialSlider {
    constructor(container) {

        this.container = container
        this.mobile = window.matchMedia("(max-width: 768px)").matches
        const options = {
            container: '.testimonialSlider',
            navContainer: '.testimonialControls',
            navAsThumbnails: true,
            nav: true,
            autoWidth: false,
            controls: false,
            edgePadding: 0,
            items: 1,
            gutter: 35,
            speed: 400,
            slideBy: '1',
            autoplay: false,
            loop: true,
            preventScrollOnTouch: 'auto',

        }
        if (this.mobile) {
            options.navContainer = '.testimonialControlsMobile'
        }
        this.partnerSlider = tns(options)

        this.sliderIndicator = this.container.querySelector('.sliderIndicator')
        this.sliderButtons = this.container.querySelectorAll('.sliderButton')

        //move the indicator to first button
        this.sliderIndicator.style.transform = "translateX(" + this.sliderButtons[0].offsetWidth / 2 + "px)"

        this.attachEventListeners()

    }
    attachEventListeners() {
        this.sliderButtons.forEach(sliderButton => {
            sliderButton.addEventListener('click', (e) => {
                let translate = parseInt(e.target.parentElement.offsetLeft) + parseInt(e.target.parentElement.offsetWidth) / 2
                this.sliderIndicator.style.transform = "translateX(" + translate + "px)"

            })
        })
    }

}