import {
  tns
}
from "tiny-slider/src/tiny-slider.module.js";

export default class partnerSlider {
  constructor(container) {
    const slideNext = container.querySelector('.slideNext');
    const slidePrev = container.querySelector('.slidePrev ');
    const options = {
      container: '.partnerSlider',
      autoWidth: false,
      controls: false,
      nav: false,
      edgePadding: 0,
      items: 2.5,
      gutter: 0,
      speed: 400,
      slideBy: '1',
      autoplay: false,
      loop: true,
      touch: true,
      mouseDrag: true,
    }

    this.mobile = window.matchMedia("(max-width: 768px)").matches
    if (this.mobile) {
      options.items = 1.25
    }
    this.partnerSlider = tns(options)


    slideNext.addEventListener("click", (event) => {
      this.partnerSlider.goTo('next');
    })

    slidePrev.addEventListener("click", (event) => {
      this.partnerSlider.goTo('prev');
    })


  }
}