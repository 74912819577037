export default class popupSticky {
  constructor() {
    this.mobile = window.matchMedia("(max-width: 768px)").matches;
    this.popupSticky = document.querySelector(".popupSticky");
    this.opened = false;
    if (this.popupSticky) {
      this.attachEventListner();
    }
  }
  attachEventListner() {
    window.addEventListener("resize", () => {
      this.mobile = window.matchMedia("(max-width: 768px)").matches;
    });
    if (!this.mobile) {
      document.addEventListener(
        "scroll",
        () => {
          if (window.scrollY > window.innerHeight * 0.8) {
            this.hidePopup();
          } else {
            this.showPopup();
          }
        },
        { passive: true }
      );
      this.popupSticky.addEventListener("mouseenter", () => {
        this.showPopup();
      });
      this.popupSticky.addEventListener("mouseleave", () => {
        if (window.scrollY > window.innerHeight * 0.8) {
          this.hidePopup();
        }
      });
    } else {
      this.hidePopup();
      this.popupSticky.addEventListener("click", () => {
        this.opened = !this.opened;
        if (this.opened) {
          this.showPopup();
        } else {
          this.hidePopup();
        }
      });
      document.addEventListener("scroll", () => {
        this.hidePopup();
      });
    }
  }
  hidePopup() {
    this.popupSticky.style.transform = "translateX(-95%)";
  }
  showPopup() {
    this.popupSticky.style.transform = "translateX(0)";
  }
}
