export default class scrollProgress {
    constructor(container, stepSelector = '.steps ') {
        this.container = container

        if (this.container) {
            this.steps = Array.from(this.container.querySelectorAll(stepSelector))
            this.stepIndicators = Array.from(this.container.querySelectorAll('[data-ref]'))
            this.initializeObserver()
            this.onClickEvent()
        }
    }
    initializeObserver() {
        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.stepIndicators.forEach(stepIndicator => {
                        stepIndicator.classList.remove('step-active')
                    })
                    let activeSteps = Array.from(this.container.querySelectorAll('[data-ref="' + entry.target.id + '"]'))
                    activeSteps.forEach(activeStep => {
                        activeStep.classList.add('step-active')
                    })
                }
            })
        }, {
            rootMargin: '0px',
            threshold: 0.8
        })
        this.steps.forEach(element => {
            this.observer.observe(element)
        })
    }
    onClickEvent() {
        this.stepIndicators.forEach(stepIndicator => {
            stepIndicator.addEventListener('click', e => {

                let active = this.container.querySelector('#' + e.target.getAttribute('data-ref'))
                window.scrollTo({
                    top: active.getBoundingClientRect().top + window.pageYOffset - document.querySelector('nav').offsetHeight,
                    behavior: 'smooth'
                })
            })
        })

    }


}