export default class scrollProgress {
    constructor(){
        this.container = document.querySelector('.headerContainer')
        if(this.container){
            this.progress = this.container.querySelector('.progress ')
            this.steps = this.container.querySelectorAll('.steps ')
            this.step = this.container.querySelectorAll('.step ')
            this.progressOffset = 200 //when does white progress bar start to fill up
           
            this.progress.style.height = 0
            this.attachEventListeners()
        }
    }
    attachEventListeners(){
        window.addEventListener( 'scroll' , (e) => {
            if( -this.container.getBoundingClientRect().top < this.container.offsetHeight){
                this.progress.style.height = - this.container.getBoundingClientRect().top + this.progressOffset + 'px'
            }
            for(let i = 0; i < this.step.length ; i++){
                if(this.steps[i].offsetTop < - this.container.getBoundingClientRect().top + this.progressOffset){
                    this.step[i].classList.remove('bg-green-200')
                    this.step[i].classList.add('bg-white')
                }
                else if(this.steps[i].offsetTop > - this.container.getBoundingClientRect().top + this.progressOffset){
                    this.step[i].classList.add('bg-green-200')
                    this.step[i].classList.remove('bg-white')
                }
            }
        })
    }
}