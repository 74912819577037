import { tns } from "tiny-slider/src/tiny-slider.module.js";

export default class historySlider {
  constructor(container) {
    this.container = container;
    this.sliderContainer = this.container.querySelector(".historySlider");
    this.slideNext = this.container.querySelector(".slideNext");
    this.slidePrev = this.container.querySelector(".slidePrev ");
    this.images = this.container.querySelectorAll(".slideImg");
    this.points = this.container.querySelectorAll(".slidePoint");
    this.timeline = this.container.querySelector(".sliderTimeline");
    //initial disable slidePrev
    this.slidePrev.classList.add("disabled");

    this.slider = tns({
      container: ".historySlider",
      navContainer: ".slidePoints",
      controlsContainer: ".controlsContainer",
      edgePadding: 0,
      speed: 400,
      slideBy: "1",
      autoplay: false,
      loop: false,
      preventScrollOnTouch: "auto",
    });

    this.attachEventListeners();
  }
  attachEventListeners() {
    this.slideNext.addEventListener("click", (event) => {
      this.activeSlide = this.container.querySelector(".tns-slide-active");
      this.activePoint = this.container.querySelector(".tns-nav-active");
      this.changeImage(this.activeSlide);
      this.moveTimeline(this.activePoint);
    });

    this.slidePrev.addEventListener("click", (event) => {
      this.activeSlide = this.container.querySelector(".tns-slide-active");
      this.activePoint = this.container.querySelector(".tns-nav-active");
      this.changeImage(this.activeSlide);
      this.moveTimeline(this.activePoint);
    });
    this.points.forEach((slidePoint) => {
      slidePoint.addEventListener("click", (event) => {
        this.changeImage(event.target);
        this.moveTimeline(event.target);
      });
    });
    this.sliderContainer.addEventListener("transitionstart", () => {
      this.activeSlide = this.container.querySelector(".tns-slide-active");
      this.activePoint = this.container.querySelector(".tns-nav-active");
      this.changeImage(this.activeSlide);
      this.moveTimeline(this.activePoint);
    });
    this.sliderContainer.addEventListener("transitionend", () => {
      if (this.slideNext.getAttribute("aria-disabled") == "true") {
        this.slideNext.classList.add("disabled");
      } else if (this.slidePrev.getAttribute("aria-disabled") == "true") {
        this.slidePrev.classList.add("disabled");
      } else {
        this.slideNext.classList.remove("disabled");
        this.slidePrev.classList.remove("disabled");
      }
    });
  }
  changeImage(activeElement) {
    this.images.forEach((image) => {
      image.classList.remove("opacity-100");
      image.classList.add("absolute", "opacity-0");
    });
    let activeImg = this.container.querySelector(
      'div.slideImg[data-index="' + activeElement.dataset.index + '"]'
    );
    activeImg.classList.add("opacity-100");
    activeImg.classList.remove("absolute", "opacity-0");
  }
  moveTimeline(activeElement) {
    this.timeline.style.transform =
      "translateX(" + -activeElement.offsetLeft + "px)";
  }
}
