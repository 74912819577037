import { tns }
  from "tiny-slider/src/tiny-slider.module.js";


export default class teamSlider {
    constructor(container){
        const slideNext = container.querySelector('.slideNext');
    const slidePrev = container.querySelector('.slidePrev ');

    let slider = tns({
      container: '.teamSlider',
      controls: false,
      edgePadding: 0,
      autoWidth: true,
      gutter: 30,
      items: 1,
      speed: 400,
      slideBy: '1',
      autoplay: false,
      loop: true,
      preventScrollOnTouch: 'auto',
      responsive:{
        640: {
            items: 1,
            gutter:0
          },
          768: {
            items: 2,
            gutter:30
          },
          1024: {
            gutter: 35,
            items: 3
          },
          1280: {
            gutter: 30,
            items: 3,
          }
      }
      
    });
    slideNext.addEventListener("click", (event) => {
      slider.goTo('next');
    })

    slidePrev.addEventListener("click", (event) => {
      slider.goTo('prev');
    })
    

    }
}