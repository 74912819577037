import axios from 'axios';

export default class ContactForm {
    constructor() {
        this.forms = document.querySelectorAll('.ajaxForm');

        if (! this.forms.length) return;

        this.attachEventListners();
    }

    attachEventListners(){
        for(let i = 0; i < this.forms.length; i++) {

            this.forms[i].addEventListener('submit', (event) => {

                console.log("submitting");


                if (! this.forms[i].querySelector('.ajaxFormFailure').classList.contains('hidden')) {
                    this.forms[i].querySelector('.ajaxFormFailure').classList.add('hidden');
                }

                event.preventDefault();

                let formData = new FormData(this.forms[i]);

                axios.post('/wp/wp-admin/admin-ajax.php', formData)
                .then((response) => {
                    this.forms[i].reset();
                    this.forms[i].classList.add('hidden');
                    this.forms[i].parentElement.querySelector('.ajaxFormSuccess').classList.remove('hidden');
                })
                .catch((error) => {
                    this.forms[i].querySelector('.ajaxFormFailure').classList.remove('hidden');
                });
            })
        }

    }
}