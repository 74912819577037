import { tns }
  from "tiny-slider/src/tiny-slider.module.js";

export default class relatedNewsSlider {
    constructor(container){
        
    const slideNext = container.querySelector('.slideNext');
    const slidePrev = container.querySelector('.slidePrev ');
    this.sliderContainer = container.querySelector('.relatedNewsSlider')

    let slider = tns({
      container: '.relatedNewsSlider',
      controlsContainer:'.relatedSliderButtons ',
      controls: true,
      edgePadding: 0,
      gutter: 30,
      speed: 400,
      slideBy: 'page',
      autoplay: false,
      loop: false,
      preventScrollOnTouch: 'auto',
      responsive: {
        640: {
          fixedWidth: 300,
          gutter: 30,
          items: 1
        },
        768: {
          gutter: 30,
          items: 2
        },
        1024: {
          gutter: 35,
          fixedWidth: 280,
          items: 3
        },
        1280: {
          gutter: 30,
          fixedWidth: 350,
          items: 3
        }
      }
    });

    
    this.sliderContainer.addEventListener('transitionend', () => {
      if(slideNext.getAttribute("aria-disabled") == 'true' ){
        slideNext.classList.add('disabled')
      }
      else if(slidePrev.getAttribute("aria-disabled") == 'true' ){
        slidePrev.classList.add('disabled')
      }
      else{
        slideNext.classList.remove('disabled')
        slidePrev.classList.remove('disabled')
      }
    })
    }
}