/**
 * Simple responsive navigation handling
 *
 * data-dropdown-id Unique id, used to open the secondary menu
 * data-toggle-dropdown-id Which secondary menu to open, when clicked
 */

export default class Navigation {
  constructor(container) {
    this.container = document.querySelector(container);

    // If container does not exist, do nothing
    if (!this.container) return;

    this.buttonToggle = this.container.querySelector('.navigation__toggle'); // Mobile toggle button
    this.buttonBackList = this.container.querySelectorAll('.navigation__back'); // Mobile all back buttons
    this.buttonSecondaryList = this.container.querySelectorAll('[data-toggle-dropdown-id]'); // List of all secondary togglers
    this.langButton = this.container.querySelector('.lang__active'); //Mobile language active
    this.langSubItems = this.container.querySelector('.lang__subitems') //Mobile language container

    this.classOpen = 'navigation--open'; // Mobile: We apply this to the container
    this.classOverflow = 'overflow-hidden'; // Mobile: We apply this to the body, so we stop scrolling
    this.classHidden = 'hidden'; // Display none class

    // Click event listener, toggle mobile menu
    this.buttonToggle.addEventListener('click', this.toggleNavigation.bind(this));

    //Add language event listner
    this.toggleLangMenu()

    // On resize, close the navigation if open
    window.addEventListener('resize', () => {
      if (this.container.classList.contains(this.classOpen)) {
        this.toggleNavigation();
        this.closeSecondary();
      }
    }, {
      passive: true
    });

    // When we click the back button, hide all open secondary menus
    this.buttonBackList.forEach((element) => {
      element.addEventListener('click', this.closeSecondary.bind(this));
    });

    // Add event listeners for toggling secondary menus
    this.buttonSecondaryList.forEach((element) => {
      element.addEventListener('click', () => {
        this.container.querySelector(`[data-dropdown-id="${element.getAttribute('data-toggle-dropdown-id')}"]`).classList.toggle(this.classHidden);
        // this.container.querySelector('.megaCta').classList.toggle(this.classHidden);
      });
    });
  }

  /**
   * Show navigation on mobile
   */
  toggleNavigation() {
    this.container.classList.toggle(this.classOpen);
    document.querySelector('body').classList.toggle(this.classOverflow);

    // We hide the topbar
    document.querySelector('.topbar').classList.toggle('hidden');


    // The first path is for the hamburger menu, the second one is the close icon
    this.buttonToggle.querySelector('svg *:nth-child(2)').classList.toggle('hidden');
    this.buttonToggle.querySelector('svg *:nth-child(3)').classList.toggle('hidden');
  }

  // Open laguange tab on mobile
  toggleLangMenu() {
    if (this.langButton) {
      this.langButton.addEventListener('click', () => {
        this.langSubItems.classList.toggle('invisible')
      })
    }
  }

  /**
   * Closes all secondary menus that are open
   */
  closeSecondary() {
    this.container.querySelectorAll('[data-dropdown-id]').forEach((element) => {
      if (!element.classList.contains(this.classHidden)) {
        element.classList.add(this.classHidden);
        // this.container.querySelector('.megaCta').classList.toggle(this.classHidden);
      }
    });
  }
}