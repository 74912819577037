export default class questionnaire {
    constructor(container) {
        this.mobile = window.matchMedia("(max-width: 768px)").matches
        if(this.mobile){
            return
        }
        else{
        this.container = container
        this.tag = this.container.querySelector('.questionTag')
        this.intro = this.container.querySelector('.questionIntro')
        this.greenBackground = this.container.querySelector('.questionGreenBg')
        this.exitButton = this.container.querySelector('.questionExit')
        this.questions = this.container.querySelectorAll('.question')
        this.answer = this.container.querySelectorAll('.answer')
        this.questionIndex = this.container.querySelectorAll('.questionIndex')
        this.progressBar = this.container.querySelector('.progressBar')
        this.currentIndex = 0
        this.timer = null

        //set porgress bar to first question
        this.progressBar.style.height = this.questionIndex[0].getBoundingClientRect().top + this.questionIndex[0].offsetHeight / 2 + 'px'
        this.attachEventListeners()
        }

    }
    attachEventListeners() {
        // event if they click on the sidebar open the questionnaire and started transition timer
        this.container.addEventListener('click', () => {
            this.tag.classList.add('opacity-0')
            document.body.classList.add('overflow-hidden')
            this.container.classList.add('w-full', 'inset-0', 'z-50')
            this.container.classList.remove('cursor-pointer')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            this.intro.style.transform = 'translateX(0)'
            this.timer = window.setTimeout( () => {
                this.greenBackground.style.transform = 'translateX(0)'
                this.questionIndex[0].classList.remove('opacity-0')
            }, 4000)

        })
        //if they click skip the intro and slide the green background with questions nad cancel the timer
        this.intro.addEventListener('click', () => {
            this.greenBackground.style.transform = 'translateX(0)'
            this.questionIndex[0].classList.remove('opacity-0')
            if (this.timer != null) {
                window.clearTimeout(this.timer); 
                this.timer = null;
              }
        })
        //to prevent the container click event on firing if you missclick 
        this.greenBackground.addEventListener('click', () =>{
            event.stopPropagation()
        })

        //exit the questionnaire, close the container and put it back in its original state + reset the questions 
        this.exitButton.addEventListener('click', () => {
            this.intro.style.transform = 'translateX(-100%)'
            this.container.classList.remove('w-full', 'inset-0', 'z-50')
            this.container.classList.add('cursor-pointer')
            document.body.classList.remove('overflow-hidden')
            this.greenBackground.style.transform = 'translateX(-100%)'
            this.tag.classList.remove('opacity-0')
            this.questions.forEach(element => {
                element.style.transform = 'translateY(100%)'
            })
            this.questionIndex[this.currentIndex + 1].classList.remove('active')
            this.currentIndex = 0
            this.questions[this.currentIndex].style.transform = 'translateY(0)'
            this.progressBar.style.height = this.questionIndex[this.currentIndex].getBoundingClientRect().top + this.questionIndex[this.currentIndex].offsetHeight / 2 + 'px'
            this.questionIndex[this.currentIndex].classList.add('active', 'opacity-0')
            event.stopPropagation()
            if (this.timer != null) {
                window.clearTimeout(this.timer); 
                this.timer = null;
              }
        })
        //when an answer is clicked slide in next question and move the progress bar acordingly
        this.answer.forEach(element => {
            element.addEventListener('click', () => {
                event.stopPropagation()
                this.currentIndex = element.getAttribute('data-index') - 1
                this.questions[this.currentIndex].style.transform = 'translateY(-100%)'
                if (this.currentIndex < this.questions.length - 1) {
                    this.questions[this.currentIndex + 1].style.transform = 'translateY(0)'
                    this.questionIndex[this.currentIndex].classList.remove('active')
                    this.progressBar.style.height = this.questionIndex[this.currentIndex + 1].getBoundingClientRect().top + this.questionIndex[this.currentIndex + 1].offsetHeight / 2 + 'px'
                } 
            //if the questionnaiare has no more questions 
                else {
                    location.reload()
                }
                this.questionIndex[this.currentIndex + 1].classList.add('active')
                if (this.timer != null) {
                    window.clearTimeout(this.timer); 
                    this.timer = null;
                  }
            })
        });
    }
}